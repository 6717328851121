import React from "react";
import styles from "./ReviewSection.module.scss";
import androidCentralLogo from "../../../assets/android-central.svg";
import investopediaLogo from "../../../assets/investopedia.webp";
import benzingaLogo from "../../../assets/benzinga.webp";
import gadgetReviewLogo from "../../../assets/gadget-review.svg";
import gadgetGoneLogo from "../../../assets/gadget-gone.svg";
import googleRate from "../../../assets/google-rate.svg";
import accreditedBusiness from "../../../assets/accredited-business.svg";
import CustomSlider from "../../custom-slider/CustomSlider";
import {
  EDUCATION_REVIEWS,
  TESTIMONIALS_URL,
} from "../../../constants/reviews";
import Trustpilot from "../../trustpilot/Trustpilot";

const IMAGES_LIST = [
  {
    src: androidCentralLogo,
    alt: "Android Central",
    href: "https://www.androidcentral.com/phones/carriers#akko",
  },
  {
    src: investopediaLogo,
    alt: "Investopedia",
    href: "https://www.investopedia.com/best-cell-phone-insurance-5181181#best-for-policy-budget-akko",
  },
  {
    src: benzingaLogo,
    alt: "Benzinga",
    href: "https://www.benzinga.com/money/cell-phone-insurance",
  },
  {
    src: gadgetReviewLogo,
    alt: "Gadget Review",
    href: "https://www.gadgetreview.com/best-cell-phone-insurance",
  },
  {
    src: gadgetGoneLogo,
    alt: "Gadget Gone",
    href: "https://www.gadgetgone.com/blog/best-cell-phone-insurance-2022/#akko",
  },
];

const slidesList = EDUCATION_REVIEWS.map((review, index) => (
  <CustomSlide key={index} {...review} />
));

const ReviewSection = () => {
  return (
    <section className={styles["landing-page__review-section"]}>
      <div className={styles["landing-page__container"]}>
        <div className={styles["landing-page__container-column"]}>
          <div className={styles["landing-page__review-heading"]}>
            <h2>
              Protect the tech you rely on with the highest-rated provider in
              the U.S.
            </h2>
            <p>
              Top Rated on Google and TrustPilot by happy customers. #1 ranked
              by GadgetReview.com (over AppleCare, Geek Squad, and SquareTrade)
              and more...
            </p>

            <div className={styles["landing-page__review-logos"]}>
              <img
                loading="lazy"
                src={googleRate}
                alt="Google rating of 4.6 stars"
              />
              <Trustpilot />

              <img
                loading="lazy"
                src={accreditedBusiness}
                alt="Accreduited business logo"
              />
            </div>
          </div>

          <div className={styles["landing-page__review-actions"]}>
            <div>
              <CustomSlider slides={slidesList} options={{ loop: true }} />
            </div>

            <a
              className={`${styles["landing-page__ratings-link"]} btn-lg btn btn-secondary__education`}
              href={TESTIMONIALS_URL}
            >
              More ratings & reviews
            </a>
          </div>
        </div>

        <div className={styles["landing-page__container-column"]}>
          <div>
            <span className={styles["landing-page__rated-by-title"]}>
              Top Rated By
            </span>
            <div className={styles["landing-page__rated-by-logos"]}>
              {IMAGES_LIST.map(({ href, alt, src }) => (
                <div key={alt}>
                  <a
                    className={`${styles["landing-page__rated-by-logos-link"]}`}
                    href={href}
                    target="_blank"
                    aria-label={`Visit ${alt} Site`}
                  >
                    <img loading="lazy" alt={`${alt} Logo`} src={src} />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function CustomSlide({ review, author }: { review: string; author: string }) {
  return (
    <div className={styles["landing-page__review-slide"]}>
      <blockquote>
        <p>{review}</p>
      </blockquote>

      <figcaption>
        <span>{author}</span>
      </figcaption>
    </div>
  );
}

export default ReviewSection;
