import React from "react";
import styles from "./EducationHeader.module.scss";
import { ReactComponent as AKKOLogo } from "../../../assets/AKKO-logo.svg";
import Trustpilot from "../../trustpilot/Trustpilot";

const EducationHeader = () => {
  return (
    <header className={styles["landing-page__header"]}>
      <div className={styles["landing-page__container"]}>
        <a
          href="https://education.getakko.com/"
          aria-label="Visit AKKO Education site"
        >
          <AKKOLogo className={styles["landing-page__akko-logo"]} />
        </a>

        <Trustpilot />
      </div>
    </header>
  );
};

export default EducationHeader;
