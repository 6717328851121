import React from "react";
import styles from "./PlanSection.module.scss";
import PlanCard, { PlanCardProps } from "../../plan-card/PlanCard";

interface PlanSectionProps {
  name: string;
  plansList: PlanCardProps[];
}

const PlanSection = React.forwardRef<HTMLElement, PlanSectionProps>(
  ({ name, plansList }, ref) => {
    return (
      <section className={styles["landing-page__plan-section"]} ref={ref}>
        <h2>{name} Plans</h2>
        <p>Check out the information your plan options below.</p>
        <div className={styles["landing-page__plan-cards"]}>
          {plansList.map((plan) => (
            <PlanCard key={plan.id} {...plan} />
          ))}
        </div>
      </section>
    );
  }
);

export default PlanSection;
