import React from "react";
import styles from "./PlanCard.module.scss";
import { Button } from "react-bootstrap";
import CheckList from "../check-list/CheckList";

export interface PlanCardProps {
  id: string;
  title: string;
  value: string;
  coverageAmount: string;
  coverageList: string[];
}

const PlanCard = ({
  title,
  value,
  coverageAmount,
  coverageList,
}: PlanCardProps) => {
  return (
    <div className={styles["plan-card"]}>
      <div className={styles["plan-card__container"]}>
        <span className={styles["plan-card__title"]}>{title}</span>
        <span className={styles["plan-card__coverage"]}>
          {coverageAmount} Coverage
        </span>
        <span className={styles["plan-card__value"]}>{value}</span>
        <Button
          className={`${styles["plan-card__button"]} btn-lg`}
          variant="primary__education"
        >
          Get Plan
        </Button>
        <CheckList list={coverageList} />
      </div>
    </div>
  );
};

export default PlanCard;
