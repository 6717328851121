import { EducationLandingPageInterface } from "../types/education";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { EducationProduct } from "../types/product";

const parseEducationLandingPageApi = (
  item: any
): EducationLandingPageInterface => {
  return {
    slug: item.slug,
    title: item.title,
    description: item.description,
    logoUrl: item.logo_url,
    school: {
      slug: item.school.slug,
      districtOwnerSlug: item.school.district_owner_slug,
      name: item.school.name,
      type: item.school.type,
    },
    productPrices: item.product_prices.map(
      ({ title, product_price }: any): EducationProduct => ({
        title: title || product_price.name,
        name: product_price.name,
        interval: product_price.interval,
        currentPrice: product_price.current_price,
        retailPrice: product_price.retail_price,
        coverage: product_price.product.protection_plan.max_device_value_usd,
        deductible: product_price.product.deductible_schedules[0].deductible,
        coveredLossTypeCategories:
          product_price.product.covered_loss_type_categories,
      })
    ),
  };
};

export const educationLandingPageAtomFamily = atomFamily((slug: string) =>
  atom(async () => {
    if (!slug) {
      throw new Error("No school slug provided");
    }
    const response = await fetch(
      `${process.env.REACT_APP_API}/school-landing-page/${slug}`,
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }
    const data = await response.json();

    return parseEducationLandingPageApi(data);
  })
);
