import React, { ReactNode } from "react";
import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import styles from "./CustomSlider.module.scss";

const CustomSlider = ({
  slides,
  options,
}: {
  slides: ReactNode[];
  options?: EmblaOptionsType;
  isAutoplay?: boolean;
}) => {
  const [emblaRef] = useEmblaCarousel(options, [
    Autoplay({ stopOnInteraction: false, stopOnMouseEnter: true }),
  ]);

  return (
    <div className={styles["embla"]}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className={styles["embla__container"]}>
          {slides.map((slide, index) => (
            <div key={index} className={styles["embla__slide"]}>
              {slide}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomSlider;
