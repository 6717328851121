import posthog from "posthog-js";
import { PostHogEvent } from "../constants/posthog";

export const initTracking = function (): void {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY as string, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
  });

  posthog.register({
    commitRef: process.env.REACT_APP_NETLIFY_COMMIT_REF,
    isEducation: process.env.REACT_APP_IS_EDUCATION,
  });
};

export const trackEvent = function (
  event: PostHogEvent,
  payload: Record<string, any>
): void {
  posthog.capture(event, payload);
};

export const identifyUser = function (userId: string): void {
  posthog.identify(`user_${userId}`, { usedCheckoutV4: true });
};

export const reset = function (): void {
  posthog.reset();
};
