import React, { FunctionComponent, SVGProps } from "react";
import styles from "./InsuranceCard.module.scss";

interface InsuranceCardProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  title?: string;
  description?: string;
}

const InsuranceCard = ({
  icon: Icon,
  title,
  description,
}: InsuranceCardProps) => {
  return (
    <div className={styles["insurance-card"]}>
      <Icon className={styles["insurance-card__icon"]} />
      <span className={styles["insurance-card__title"]}>{title}</span>
      <span className={styles["insurance-card__description"]}>
        {description}
      </span>
    </div>
  );
};

export default InsuranceCard;
