export const TESTIMONIALS_URL = "https://testimonials.getakko.com/";

export const EDUCATION_REVIEWS = [
  {
    review:
      "I’m so thrilled by this service. For only a couple dollars more than I’d been paying Verizon just for phone insurance, now all my devices are covered. I just completed a claim of my 6 year old MacBook Pro- which I’d spilled ramen all over. Service was super rapid… I’d highly recommend AKKO.",
    author: "Robert",
  },
  {
    review:
      "AKKO took care of me with fast customer service… Smoothest transaction for insurance ever.",
    author: "Ray",
  },
  {
    review:
      "My cracked phone screen repair was a seamless experience with AKKO. They far exceeded my expectations and provided reimbursement funds the very next day.",
    author: "Ryan",
  },
  {
    review:
      "Very professional. Followed up and resolved the issue immediately highly recommend this company.",
    author: "Frederic",
  },
];
