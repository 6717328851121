import React, { useRef } from "react";
import styles from "./LandingPage.module.scss";

import SchoolSection from "../../components/education-landing-page/school-section/SchoolSection";
import InsuranceSection from "../../components/education-landing-page/insurance-section/InsuranceSection";
import PlanSection from "../../components/education-landing-page/plan-section/PlanSection";
import CoverageSection from "../../components/education-landing-page/coverage-section/CoverageSection";
import ProtectionSection from "../../components/education-landing-page/protection-section/protectionSection";
import InfoSection from "../../components/education-landing-page/info-section/InfoSection";
import EducationHeader from "../../components/education-landing-page/education-header/EducationHeader";
import EducationFooter from "../../components/education-landing-page/education-footer/EducationFooter";
import ReviewSection from "../../components/education-landing-page/review-section/ReviewSection";
import Translate from "../../components/translate/Translate";
import { initIntercom } from "../../services/intercom";
import { educationLandingPageAtomFamily } from "../../lib/education";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { NotFound } from "../NotFound/NotFound";
import { AKKOTemplate } from "../../templates/AKKOTemplate/AKKOTemplate";
import { formatPlanCardsList } from "../../utils/education-plans";
import { usePageTitle } from "../../hooks/usePageTitle";

const DEFAULT_PAGE_TITLE = "Education - AKKO";

export const LandingPage = () => {
  const plansSectionRef = useRef<HTMLElement>(null);
  const { schoolSlug = "" } = useParams();
  const landingPageAtom = educationLandingPageAtomFamily(schoolSlug);
  const loadableData = useAtomValue(loadable(landingPageAtom));

  const pageTitleSchoolName =
    loadableData.state === "hasData" &&
    `${loadableData.data.school.name} - AKKO`;

  usePageTitle(pageTitleSchoolName || DEFAULT_PAGE_TITLE);

  if (!schoolSlug || loadableData.state === "hasError") {
    return (
      <AKKOTemplate>
        <NotFound />
      </AKKOTemplate>
    );
  }

  if (loadableData.state === "loading") {
    return <LoadingSpinner />;
  }

  const {
    data: {
      title,
      description,
      logoUrl,
      school: { name: schoolName },
      productPrices,
    },
  } = loadableData;

  const planCardsList = formatPlanCardsList(productPrices);

  const handleScrollToSection = () => {
    plansSectionRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  initIntercom();

  return (
    <div className={styles["landing-page"]}>
      <EducationHeader />
      <main>
        <SchoolSection
          title={title}
          description={description}
          logoUrl={logoUrl}
          onClick={handleScrollToSection}
        />
        <InsuranceSection />
        <PlanSection
          name={schoolName}
          plansList={planCardsList}
          ref={plansSectionRef}
        />
        <ReviewSection />
        <CoverageSection />
        <ProtectionSection />
        <InfoSection />
      </main>
      <EducationFooter />
      <Translate />
    </div>
  );
};
