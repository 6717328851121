import React, { useEffect } from "react";

export const Translate = () => {
  useEffect(() => {
    (window as any).gtranslateSettings = {
      default_language: "en",
      languages: [
        "ar",
        "zh-CN",
        "nl",
        "en",
        "fr",
        "de",
        "it",
        "pt",
        "ru",
        "es",
      ],
      wrapper_selector: ".gtranslate_wrapper",
      alt_flags: { en: "usa", es: "mexico" },
    };

    const script = document.createElement("script");
    script.src = "https://cdn.gtranslate.net/widgets/latest/float.js";
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="gtranslate_wrapper" />;
};

export default Translate;
