import React from "react";
import styles from "./CoverageCard.module.scss";

interface CoverageCardProps {
  title: string;
  description: string;
  src: string;
  alt: string;
}

const CoverageCard = ({ title, description, src, alt }: CoverageCardProps) => {
  return (
    <div className={styles["coverage-card"]}>
      <img src={src} className={styles["coverage-card__image"]} alt={alt} />
      <h3 className={styles["coverage-card__title"]}>{title}</h3>
      <span className={styles["coverage-card__description"]}>
        {description}
      </span>
    </div>
  );
};

export default CoverageCard;
