import { PlanCardProps } from "../components/plan-card/PlanCard";
import { EducationProduct } from "../types/product";

import { numberToMonetary } from "./monetary";

export function formatPlanCardsList(
  productPrices: EducationProduct[]
): PlanCardProps[] {
  return productPrices.map(
    ({
      title,
      currentPrice,
      coverage,
      deductible,
      coveredLossTypeCategories,
    }) => {
      const coverageAmount = numberToMonetary(coverage);
      const coverageList = [
        `${numberToMonetary(deductible)} Deductible`,
        "Unlimited Claims",
        ...coveredLossTypeCategories,
      ];

      return {
        id: title,
        title,
        value: numberToMonetary(currentPrice ?? 0),
        coverageAmount,
        coverageList,
      };
    }
  );
}
