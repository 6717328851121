import React from "react";
import styles from "./CoverageSection.module.scss";
import { ReactComponent as RoundedTop } from "../../../assets/education-section-round-top.svg";
import { ReactComponent as ArrowLeft } from "../../../assets/left-arrow-thin.svg";
import educationQuickRegistrationImage from "../../../assets/education-quick-registration.webp";
import educationFullProtection from "../../../assets/education-full-protection.svg";
import educationEasyRepair from "../../../assets/education-easy-repair.svg";
import CoverageCard from "../../coverage-card/CoverageCard";

const coverageCardsList = [
  {
    src: educationQuickRegistrationImage,
    title: "Quick\nregistration",
    alt: "Smartphone illustration with checklist",
    description: "Choose the AKKO plan that's right for you.",
  },
  {
    src: educationFullProtection,
    title: "Full\nprotection",
    alt: "Smartphone illustration with checklist",
    description:
      "Keep going knowing you’re fully protected from whoops, oh-no’s, and not agains. Upgrade or downgrade anytime.",
  },
  {
    src: educationEasyRepair,
    title: "Easy repairs &\nreplacements",
    alt: "Smartphone illustration with checklist",
    description:
      "Get repairs done quick – often same day for phones! We pay the store or can pay you by PayPal or wire immediately upon claim approval.",
  },
];

const CoverageSection = () => {
  return (
    <section className={styles["landing-page__coverage-section"]}>
      <RoundedTop
        className={styles["landing-page__coverage-section-decorator"]}
      />
      <div className={styles["landing-page__coverage-section-title"]}>
        <ArrowLeft />
        <h2>Coverage, Simplified</h2>
        <ArrowLeft className={styles["landing-page__arrow-right"]} />
      </div>
      {/* </div> */}

      <p>AKKO makes protecting your stuff easier and more affordable.</p>
      <div className={styles["landing-page__container"]}>
        <div className={styles["landing-page__coverage-cards"]}>
          {coverageCardsList.map((card) => (
            <CoverageCard key={card.title} {...card} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CoverageSection;
