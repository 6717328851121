import React from "react";
import styles from "./InfoSection.module.scss";

const InfoSection = () => {
  return (
    <section className={styles["landing-page__info-section"]}>
      <h2>Info & FAQ</h2>
      <p>
        Don’t worry, a lot of people sometimes think this sounds “too good to be
        true” so we get asked a lot of questions.
        <br />
        You can chat with AKKO support by clicking the blue chat button on the
        bottom right of you screen!
      </p>
    </section>
  );
};

export default InfoSection;
