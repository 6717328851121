import React from "react";
import { Button } from "react-bootstrap";
import styles from "./SchoolSection.module.scss";
import educationSquareImage from "../../../assets/education-square.jpg";
import educationWideImage from "../../../assets/education-wide.webp";

interface SchoolSectionProps {
  title: string;
  description: string;
  onClick: () => void;
  logoUrl: string;
}

const SchoolSection = ({
  title,
  description,
  onClick,
  logoUrl,
}: SchoolSectionProps) => {
  return (
    <section className={styles["landing-page__school-section"]}>
      <div className={styles["landing-page__container"]}>
        <img
          className={styles["landing-page__hero-mobile"]}
          loading="lazy"
          src={educationWideImage}
          alt="Mother and children looking at their phone"
        />

        <div className={styles["landing-page__school-details"]}>
          <img
            className={styles["landing-page__school-logo"]}
            loading="lazy"
            src={logoUrl}
            alt="School Logo"
          />

          <h1>{title}</h1>

          <p>{description}</p>

          <Button
            className="btn-lg"
            variant="primary__education"
            onClick={onClick}
          >
            Choose Your Plan
          </Button>
        </div>

        <div className={styles["landing-page__hero-browser"]}>
          <img
            loading="lazy"
            src={educationSquareImage}
            alt="Mother and sons looking at their phone"
          />
        </div>
      </div>
    </section>
  );
};

export default SchoolSection;
