import React from "react";
import styles from "./InsuranceSection.module.scss";
import { ReactComponent as ShieldCheckIcon } from "../../../assets/shield-check.svg";
import { ReactComponent as GearIcon } from "../../../assets/gear.svg";
import { ReactComponent as CircleDollarIcon } from "../../../assets/circle-dollar.svg";
import { ReactComponent as InfinityIcon } from "../../../assets/infinity.svg";
import InsuranceCard from "../../insurance-card/InsuranceCard";

const INSURANCE_CARD_LIST = [
  {
    icon: ShieldCheckIcon,
    title: "Full Damage",
    description: "Accidental Damage - Drops & Spills",
  },
  {
    icon: InfinityIcon,
    title: "Easy Claims",
    description: "Seamless Claims Process",
  },
  {
    icon: GearIcon,
    title: "Swift Repairs",
    description: "Easy & Fast Repair Turnaround",
  },
  {
    icon: CircleDollarIcon,
    title: "Save Money",
    description: "Coverage Costs Less Than Repairs",
  },
];

const InsuranceSection = () => {
  return (
    <section className={styles["landing-page__insurance-section"]}>
      <div className={styles["landing-page__container"]}>
        <div className={styles["landing-page__insurance-section-title"]}>
          <h2>Finally, device insurance like it should be.</h2>
          <p>
            We built AKKO as the insurance we wanted. Full coverage. Customer
            service that truly cares. Fair prices. And no hidden terms, ever.
            Crazy? Maybe. But it’s just the right thing to do.
          </p>
        </div>

        <div className={styles["landing-page__insurance-cards"]}>
          {INSURANCE_CARD_LIST.map((card) => (
            <InsuranceCard key={card.title} {...card} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default InsuranceSection;
