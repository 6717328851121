import React, { useEffect, useRef } from "react";

const Trustpilot = () => {
  const ref = useRef<HTMLDivElement>(null);
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    if ((window as any).Trustpilot) {
      (window as any).Trustpilot.loadFromElement(ref.current, true);
      return;
    }
    scriptRef.current = document.createElement("script");
    scriptRef.current.type = "text/javascript";
    scriptRef.current.src =
      "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    scriptRef.current.async = true;
    document.head.appendChild(scriptRef.current);

    return () => {
      if (scriptRef.current && document.head.contains(scriptRef.current)) {
        document.head.removeChild(scriptRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="5ea9e8045d68020001adc639"
      data-style-height="30px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://www.trustpilot.com/review/getakko.com?utm_medium=trustbox&utm_source=MicroStar"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

export default Trustpilot;
