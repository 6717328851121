interface NumberToMonetaryOptions {
  currency?: string;
  fractionDigits?: number;
  /**
   * By default, prices are displayed with two decimal places (cents).
   * However, if the number is a whole number (i.e. no cents), some
   * use cases may require omitting the decimal part. This can be achieved
   * by setting autoAdjustFractionDigits to true.
   */
  autoAdjustFractionDigits?: boolean;
}

export const numberToMonetary = (
  amount: number,
  options?: NumberToMonetaryOptions
): string => {
  const {
    currency = "USD",
    fractionDigits = 2,
    autoAdjustFractionDigits = true,
  } = options ?? {};

  const hasFraction = amount % 1 !== 0;
  const digits = hasFraction ? fractionDigits : 0;

  return amount.toLocaleString("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: autoAdjustFractionDigits ? digits : fractionDigits,
  });
};
