import React from "react";
import styles from "./ProtectionSection.module.scss";
import educationInfoImage from "../../../assets/education-info.webp";

const ProtectionSection = () => {
  return (
    <section className={styles["landing-page__protection-section"]}>
      <img
        className="img-fluid"
        loading="lazy"
        src={educationInfoImage}
        alt="Typing on a notebook"
      />
      <div>
        <h2>We protect what you rely on</h2>
        <p>
          We provide protection so good that mishaps become mere hiccups -
          getting you back to life like nothing (cracks, breaks, or spills) ever
          happened.
        </p>
        <p>
          Drop your single-device protection plan and get more for less with
          AKKO.
        </p>
      </div>
    </section>
  );
};

export default ProtectionSection;
