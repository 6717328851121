import React from "react";

const LoadingSpinner = () => {
  return (
    <div
      className="d-flex justify-content-center"
      style={{ paddingTop: "1em", paddingLeft: "1em" }}
    >
      <div className="d-flex justify-content-center flex-column align-content-center">
        <div
          className="spinner-border"
          role="status"
          style={{ color: "var(--border-color)" }}
        />
        <p className="mt-4" style={{ marginLeft: "-1em" }}>
          Loading...
        </p>
      </div>
    </div>
  );
};

export default LoadingSpinner;
