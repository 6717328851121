import React from "react";
import { Button } from "react-bootstrap";
import styles from "./EducationFooter.module.scss";
import { ReactComponent as AKKOLogo } from "../../../assets/AKKO-logo.svg";

const EducationFooter = () => {
  return (
    <footer className={styles["landing-page__footer"]}>
      <div className={styles["landing-page__footer-akko-logo"]}>
        <span className="text-sm">Powered by</span>
        <AKKOLogo />
      </div>
      <Button className="btn-md" variant="tertiary__education">
        School Login
      </Button>
    </footer>
  );
};

export default EducationFooter;
