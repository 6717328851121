import React from "react";
import styles from "./CheckList.module.scss";
import { ReactComponent as CheckIcon } from "../../assets/thin-checkmark.svg";

const CheckList = ({ list }: { list: string[] }) => {
  return (
    <ul className={styles["check-list"]}>
      {list.map((item) => (
        <li className={styles["check-list__item"]} key={item}>
          <CheckIcon />
          <span>{item}</span>
        </li>
      ))}
    </ul>
  );
};

export default CheckList;
